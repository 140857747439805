.bottom_info{
  margin: 50px 0;
}
.bottom_info h1{
  font-size: 28px;
    margin-top: 30px;
    /* color: #e87800; */
    letter-spacing: .5px;
    color: #000;
}
.bottom_info>h1{
  /* background-color: #ffe7aa;
  text-decoration: underline;
  width: fit-content;
  margin: auto;
  padding: 10px 20px;
  border-radius: 8px; */
  text-align: center;
}
.addonInfo{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  margin: 5px 0 30px 0;
}
.addonInfo h1{
  font-weight: 100;
  font-size: 24px;
  margin: 0;
  margin-bottom: 15px;
}
.addonInfo p{
  line-height: 1.3;
  font-size: 14px;
  color: #6d421f;
  font-family: "Poppins";
}
.addonInfo ul {
  font-family: "Poppins";
  padding-top: 10px;
}
.addonInfo ul li {
  font-family: "Poppins";

}
.width_100{
  width: 100%;
  background: #ffe7aa;
  border-radius: 20px;
  margin: 10px;
  padding: 30px;
  position: relative;
  z-index: 10;
}
.width_100:nth-child(even){
  background-color: #fff6df;
}
.width_100::before{
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  z-index: -1;
}
.width_100:nth-child(even)::before{
  background-color: #faeabf;
  bottom: 0;
  border-radius: 0 100% 0 20px;
  left: 0;
}
.width_100:nth-child(odd)::before{
  background-color: #eca34d;
  bottom: 0;
  right: 0;
  border-radius: 100% 0 20px 0;
}
.bottom_info_img{
  width: 15%;
  margin: 20px auto;
}
.flex_row{
  flex-direction: row;
  justify-content: center
}
/* .flex_row .width_100{
  width: 48%;
  background-color: #fff6df;
}
.flex_row .width_100:nth-child(even){
  background: #ffe7aa;
}
.flex_row .width_100::before{
  top: 0;
  border-radius: 0 20px 0 100%;
}
.right_0_position::before{
  right: 0;
}
.flex_row .width_100:nth-child(even)::before{
  background-color: #eca34d;
}
.flex_row .width_100:nth-child(odd)::before{
  background-color: #faeabf;
} */
@media screen and (max-width: 1050px) {
  .width_100::before{
    width: 100px;
    height: 100px;
  }
  .flex_row .width_100{
    width: 100%;
  }
  .bottom_info_img{
    width: 30%;
  }
}
.createToken__header {
  background: #ffe7aa !important;
  padding: 1.5rem 2rem !important;
}

.createToken__header-heading {
  color: rgb(51, 51, 51) !important;
}

.createToken__header-subHeading {
  color: #d4740e !important;
}

.createToken__formContainer {
  background: #ffe7aa !important;
}

.createToken__formFiledHeading {
  color: #d4740e !important;
  font-weight: 500 !important;
  margin-bottom: 0.25rem;
}

.createToken__formFiledInput {
  background-color: #ffd36d !important;
  color: black !important;
  font-size: 0.9rem;
  font-weight: 300 !important;
  border: none !important;
}
.createToken__formFiledInput::placeholder {
  color: #a57e24 !important;
  font-weight: 300 !important;
}

.createToken__formSelectTab {
  height: 40px;
  outline: 0;
  padding: 0 16px;
  width: 100%;
  border-radius: 10px;
  box-shadow: inset 0px 2px 2px -1px rgb(74 74 104/ 10%);
  display: block;
  background-color: #ffd36d !important;
  color: black !important;
  font-size: 0.9rem;
  font-weight: 300 !important;
  border: none;
}

.createToken__createTokenButton {
  height: 48px;
  font-family: 'Poppins', sans-serif !important;
  text-transform: capitalize;
  font-weight: 600;
  background-color: #e87800;
  color: white;
  box-shadow: none;
  font-size: 0.9rem;
  padding: 0rem 1rem;
  border-radius: 12px;
  border: none;
  outline: none;
  transition: 0.1s;
  cursor: pointer;
}
.createToken__createTokenButton:hover {
  background-color: #f08f27;
}
.createToken__createTokenButton:disabled {
  background-color: #c9853c;
  cursor: not-allowed;
}

.antiBot__checkBox {
  background-color: #e87800 !important;
}

.antibotCheckText {
  color: #e87800 !important;
}

.createToken__messageContainer {
  text-align: center;
  color: #e87800;
  margin-top: 1rem;
}

@media screen and (max-width: 580px) {
  .createToken__header {
    padding: 0rem !important;
  }

  .createToken__header-container {
    padding: 0rem;
  }

  .createToken__header-heading {
    font-size: 2.5rem !important;
  }
  .createToken__header-subHeading {
    font-size: 1.25rem !important;
  }
  .createToken__doubleFormFiledContainer {
    flex-direction: column !important;
  }
  .createToken__doubleFormFiledContainer > div:first-child,
  .createToken__doubleFormFiledContainer > div:last-child {
    width: 100% !important;
  }
}
