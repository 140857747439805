.tabMenu__tabButton {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  padding: 1.2rem 2rem !important;
  background-color: #ffdf7d !important;
  color: rgb(51, 51, 51) !important;
  font-weight: 600 !important;
}

.tab__active {
  background-color: #ffeaba !important;
}

.body__Wrapper > div:first-child {
  background-color: #ffeaba !important;
}

.body__wrapper-heading {
  color: rgb(51, 51, 51) !important;
}
.histroyIcon,
.gearIcon {
  fill: rgb(51, 51, 51) !important;
}
#swap-currency-input > div:first-child,
#swap-currency-output > div:first-child {
  background-color: #f9c348 !important;
}

.swap__currency__label {
  color: rgb(51, 51, 51) !important;
}
.token-amount-input {
  background-color: #ffde91 !important;
  color: black !important;
  font-size: 1rem;
  font-weight: 300 !important;
}
.token-amount-input::placeholder {
  color: #a57e24 !important;
  font-weight: 300 !important;
}
.open-currency-select-button {
  background-color: #ffd574 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 300 !important;
}
#pair,
.recipientAddressHeading {
  color: rgb(51, 51, 51) !important;
  font-weight: 400 !important;
}
.currencyButton__container {
  justify-content: center;
  display: flex;
  align-items: center;
}
.slippage__price,
.styledBalanceMaxMini {
  color: rgb(51, 51, 51) !important;
}
.max__button {
  color: rgb(51, 51, 51) !important;
  font-weight: 300 !important;
}
.slippage__tolerance {
  color: rgb(51, 51, 51) !important;
  font-weight: 300 !important;
}
.slippage__tolerance__percentage {
  color: rgb(51, 51, 51) !important;
  font-weight: 300 !important;
}
.arrowIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.switchCurrencyWrapper {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5ae32;
  border-radius: 50%;
}
.arrowDownIcon {
  fill: rgb(51, 51, 51) !important;
}

#add-recipient-button,
#remove-recipient-button {
  border: 2px solid #e68600 !important;
  font-weight: 300 !important;
  font-size: 0.8rem;
  height: 38px;
  padding: 0rem 0.5rem;
  color: #e68600 !important;
}
.recipientAddressHeading__link {
  color: #a56101 !important;
  font-weight: 300 !important;
  font-size: 0.75rem !important;
}

/* 0xFafb4C69a667bd6cA279b55db4151415B5aA5964 */
.recipient-address-input {
  background-color: #ffde91 !important;
  color: rgb(51, 51, 51) !important;
  padding: 10px !important;
  border-radius: 10px !important;
  font-weight: 300 !important;
  font-size: 0.85rem !important;
}
.recipient-address-input::placeholder {
  color: #a57e24 !important;
  font-weight: 300 !important;
}

.settingsModalContainer,
.expertModeModalContainer,
.transactionModalContainer {
  background-color: #ffeaba !important;
  border: none !important;
  border-radius: 20px !important;
}
.transactionModalContainer div:first-child {
  background: #ffeaba !important;
}
.settingsModalContainer h2,
.expertModeModalContainer h2,
.transactionModalContainer h2 {
  color: rgb(51, 51, 51);
  font-weight: 300;
}
.settingsModalContainer svg,
.expertModeModalContainer svg,
.transactionModalContainer svg {
  fill: rgb(51, 51, 51);
}
.globalHeading {
  color: rgb(51, 51, 51) !important;
  font-weight: 300 !important;
  font-size: 0.9rem !important;
}
.transactionSpeedHeading,
.swapsAndLiquidityHeading,
.swapsAndLiquidity__toleranceHeading {
  color: rgb(51, 51, 51) !important;
  font-weight: 300 !important;
}

.transactionSpeedButton,
.selectedTransactionSpeedButton,
.selectedSlippageButton,
.unselectedSlippageButton {
  font-weight: 600 !important;
  height: 38px !important;
  font-size: 0.9rem !important;
  font-family: 'Poppins', sans-serif !important;
  box-shadow: none !important;
}
.selectedTransactionSpeedButton,
.selectedSlippageButton {
  background-color: #e87800 !important;
  border: 2px solid #e87800 !important;
}
.transactionSpeedButton,
.unselectedSlippageButton {
  border: 2px solid #e87800 !important;
  color: #e87800 !important;
}
.selectedSlippageButton,
.unselectedSlippageButton {
  height: 32px !important;
  font-family: 'Days One', sans-serif !important;
  font-weight: 300 !important;
}

.slippageInputContainer {
  max-width: 120px;
}
.slippageInput,
.transactionDeadlineInput {
  height: 32px !important;
  background-color: #e87800 !important;
  font-weight: 300 !important;
  color: white !important;
  box-shadow: none !important;
  border: none !important;
}
.transactionDeadlineInput {
  max-width: 75px;
  padding: 0rem 0.5rem !important;
}

.slippageInput::placeholder,
.transactionDeadlineInput::placeholder {
  color: white !important;
}

.slippagePercentage {
  font-weight: 300 !important;
  margin-left: 4px !important;
}

.transactionSettingOptionHeading {
  color: rgb(51, 51, 51) !important;
}

#toggle-expert-mode-button + div,
#toggle-disable-multihop-button + div {
  background-color: #e87800 !important;
}

.messageText {
  color: #e87800 !important;
}

.expertMode__finalWarning {
  color: #a51101 !important;
}
.expertMode__checkBox {
  background-color: #e68600 !important;
}
.expertMode__modalConfirmation {
  color: rgb(51, 51, 51) !important;
}
#confirm-expert-mode,
.cancel__expertMode__button {
  height: 40px !important;
  box-shadow: none !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  background-color: #e87800;
}
.cancel__expertMode__button {
  margin-top: 0.25rem;
  border-color: #e87800 !important;
  color: #e87800 !important;
}

/* .recentTransactionModalBody{
    border: 2px solid red !important;
} */

.noRecentTransactionModalHeading,
.recentTransactionModal__heading {
  color: #e87800 !important;
}

.clearRecentTransactionButton {
  height: 38px !important;
  font-family: 'Poppins', sans-serif !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background-color: #e87800 !important;
  color: white !important;
  margin-top: 2rem;
}

.noLiquidityPoolHeading {
  color: #a56101 !important;
}

.currencySearchModal {
  background-color: #ffeaba !important;
  border: none !important;
}
.currencySearchModal svg {
  fill: rgb(51, 51, 51) !important;
}
.currencySwapModalTitle button svg {
  fill: rgb(51, 51, 51) !important;
}

.currencySwapModalTitle h2 {
  font-weight: 300 !important;
  color: rgb(51, 51, 51) !important;
}
.currencySwapModalTitle + button svg {
  fill: rgb(51, 51, 51) !important;
}

#token-search-input {
  background-color: #ffde91 !important;
  border: none !important;
  color: rgb(51, 51, 51) !important;
  box-shadow: none;
}

#token-search-input::placeholder {
  color: rgb(51, 51, 51) !important;
}

.noResultsFoundText {
  font-weight: 300 !important;
  color: #a56101 !important;
}

.selectCurrencyItem:hover {
  background-color: #ffdf7d !important;
}

.selectCurrencyItem__symbol {
  color: #e87800 !important;
}

.selectCurrencyItem__column {
  /* border: 2px solid red; */
  margin-left: 0.25rem !important;
}
.selectCurrencyItem__name {
  color: rgb(51, 51, 51) !important;
  font-size: 0.7rem !important;
}
.list-token-manage-button {
  font-family: 'Poppins', sans-serif !important;
  background-color: #e87800 !important;
  color: white !important;
  width: 100%;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  height: 40px !important;
}
.currencySwapModalFooter {
  background-color: transparent !important;
}

.manageTokenButtonTabSelected,
.manageTokenButtonTabUnSelected {
  border-radius: 3rem !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
}

.manageTokenButtonTabUnSelected {
  color: rgb(51, 51, 51) !important;
}

.manageTokenButtonTabSelected {
  color: white !important;
  background-color: #e17c0f !important;
}
#list-add-input {
  background-color: #ffde91 !important;
  border: none !important;
  color: rgb(51, 51, 51) !important;
}

#list-add-input::placeholder {
  color: rgb(51, 51, 51) !important;
}
.customTokenHeading {
  font-weight: 300 !important;
  color: #a56101 !important;
}

.removeCustomTokenButton {
  height: 40px !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  background-color: rgb(225, 124, 15) !important;
  color: white !important;
}

.tokenImportFailure,
.tokenAddressValidationWarning {
  color: #a51101 !important;
}

.tokenListsRowHeading {
  color: #e87800 !important;
}
.importLists__toggleContainer + div {
  background-color: rgb(225, 124, 15) !important;
}

.liquidityCellWrapper__text > div:first-child,
.multiplierText > div:first-child {
  background-color: transparent !important;
}
@media screen and (max-width: 560px) {
  .tabMenu__tabButton {
    font-size: 0.85rem !important;
    font-weight: 600 !important;
    padding: 1rem 1.8rem !important;
  }
  .exchange__Header h2 {
    font-size: 1.1rem !important;
  }
  .body__wrapper-heading {
    font-size: 1.1rem !important;
  }
  .histroyIcon,
  .gearIcon {
    width: 18px;
  }
  #pair {
    font-size: 0.8rem;
  }
  .token-amount-input {
    font-size: 0.8rem;
  }
}
