body {
  background-image: url('./bg.png');
  background-size: cover;
  position: relative;
}

#root {
  padding-top: 4rem;
}
.connectWalletDarkButton,
.disconnectWalletDarkButton {
  color: white;
  background-color: #832f02;
  padding: 0.7rem 1.25rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 1rem;
  transition: background-color 0.2s;
  cursor: pointer !important;
}
.connectWalletDarkButton:hover,
.disconnectWalletDarkButton:hover {
  background-color: #762900;
}

.connectWalletStretchedButton {
  background-color: #e87800;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
}

.connectWalletOutlinedButton {
  color: white;
  padding: 12px 20px;
  font-weight: 300 !important;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 14px;
  transition: background-color 0.2s;
  background: transparent;
  border: 2px solid #e5830d;
  color: #e5830d;
  cursor: pointer;
  transition: all 0.2s;
}

.connectWalletOutlinedButton:hover {
  color: #c46f07;
  border-color: #c46f07;
}

.liquidityModalBackIcon {
  fill: black !important;
}

.liquidityBottomButton {
  color: white !important;
  background-color: #e87800 !important;
  height: 40px !important;
  transition: background-color 0.2s !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  border: none !important;
  outline: none !important;
  font-size: 1rem !important;
}

.liquidityBottomButton.pancake-button--disabled,
.swapBottomButtons.pancake-button--disabled {
  color: rgb(206, 206, 206) !important;
  background-color: #9d4d21 !important;
}

.swapBottomButtons {
  color: white !important;
  background-color: #e87800 !important;
  height: 40px !important;
  transition: background-color 0.2s !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
  border: none !important;
  outline: none !important;
  font-size: 1rem !important;
}
.navBarContainer {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 80px;
  background-color: #fad876;
  z-index: 20;
}
.navBar {
  height: 100%;
  max-width: 1200px;
  margin: 0rem auto;
  display: flex;
  align-items: center;
}

.navBarWrapper {
  position: relative;
  height: 100%;
}

.logoContainer {
  width: fit-content;
  max-width: 280px;
  margin-right: 0.5rem;
}

.logoContainer img {
  max-width: 65px;
}

.linksContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
}

.logoWraper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoTextContainer {
  margin-left: 0.5rem;
  display: flex;
}

.logoTextContainer > span:first-child {
  font-size: 1.65rem;
  font-style: italic;
  font-weight: 600;
  color: #683b0a;
}

.logoTextContainer > span:last-child {
  font-size: 1.65rem;
  font-style: italic;
  font-weight: 600;
  margin-left: 0.35rem;
  color: #434342;
}

.linksContainer a {
  padding: 15px 10px;
  font-weight: 300 !important;
  font-size: 14px;
  color: rgb(51, 51, 51);
}
.walletMoneyInformation,
.mediumDevicesNavbar__walletMoneyInformation {
  padding: 12px 20px;
  background: linear-gradient(145deg, #ffa900, #e17c0f);
  border-radius: 10px;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0rem 0.5rem;
  min-width: 115px;
  text-align: center;
}

.navbar__body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
}

.hamburgerMenuContainer {
  height: 35px;
  width: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  fill: rgb(51, 51, 51);
  transition: 0.2s ease;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.hamburgerMenuContainer:hover {
  background-color: #f4c538;
}

.mediumDevicesNavbar {
  width: 100%;
  z-index: 1;
  position: absolute;
  display: none;
  transition: all 0.4s;
  background-color: #fad876;
}

/* footerStyles */

.footerContainer {
  background-color: #ecb22e;
  padding: 90px 0px;
  padding-bottom: 90px;
  padding-bottom: 0px;
  margin-top: 70px;
  margin-top: 8rem;
  position: relative;
}

.footerImageWaveContainer {
  position: absolute;
  top: -40px;
}

.footerImageWaveContainer img {
  display: block;
  max-width: 100%;
  height: auto;
}
.footerLogoContainer {
  position: absolute;
  top: -76px;
  width: 148px;
  left: 0px;
  right: 0px;
  margin: auto;
}
.footerLogoContainer img {
  display: block;
  max-width: 60%;
  margin: 0rem auto;
  height: auto;
}
.footerLogoTextContainer {
  margin-top: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.footerLogoTextContainer > span {
  font-style: italic;
  font-weight: 600;
}
.footerLogoTextContainer > span:first-child {
  color: #683b0a;
}
.footerLogoTextContainer > span:last-child {
  color: #434342;
  margin-left: 0.3rem;
}
.footerColumns__linksContainer {
  display: flex;
  flex-direction: column;
}

.footerColumns__linksContainer a {
  width: fit-content;
  padding: 0.5rem 0.75rem;
  color: rgb(51, 51, 51);
  margin: 0.35rem 0rem;
  font-size: 0.9rem;
  border-left: 2px solid #ffffff57;
}

.footerColumn h1 {
  color: rgb(51, 51, 51);
  font-size: 1.5rem;
  font-weight: 300 !important;
}
.footerColumn img {
  margin: 0.75rem 0rem;
}

.footerColumn {
  margin: 2rem;
  flex: 1;
}

.footerLinksContainer {
  max-width: 1100px;
  margin: 0rem auto;
  display: flex;
}

.footerLinksBottomContainer {
  max-width: 1100px;
  margin: 0rem auto;
  display: flex;
  height: 60px;

  align-items: center;
  justify-content: space-between;
  padding: 0rem 0.5rem;
}

.footerLinksBottomContainer p {
  font-family: 'Poppins', sans-serif;
  fill: rgb(51, 51, 51);
  font-size: 0.9rem;
}

.footerLinksBottomWrapper {
  background-color: #f6bd3d;
}

.footerIcons {
  fill: rgb(51, 51, 51);
  margin-right: 0.5rem;
  cursor: pointer;
}

.connectedWallet {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem 0.75rem;
  border-radius: 10px;
  color: #832f02;
  cursor: pointer;
  transition: 0.15s;
}

/* .connectedWallet:hover {
  background-color: #f1c748;
} */

.walletInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountFormattedAddress {
  font-size: 0.8rem;
  cursor: default;
}
.walletIcon {
  margin-right: 0.25rem;
  font-size: 1.65rem;
}
/* .farksJ .jrVDlG {
  display: none !important;
} */
.frrOzh .fcqAEy {
  display: none !important;
}
.walletDropDownIcon {
  font-size: 22px;
}
.connectedWallet {
  position: relative;
}
.navbar__dropMenuContainer {
  position: absolute;

  top: 100%;
  min-width: 100%;
  min-height: 40px;
  display: flex;
  border-radius: 10px;
  background-color: #ffe7aa;
}

.navbar__dropMenuContainer__disconnect {
  font-size: 0.85rem !important;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}
.navbar__dropMenuContainer__disconnect:hover {
  background-color: #ffe092;
}
.navbar__dropMenuContainer__disconnect span {
  margin-right: 0.5rem !important;
}

.navbar__dropMenuContainer__disconnect__icon {
  font-size: 18px;
}

/* NFT Section Started */
.nft-main-container {
  min-height: calc(100vh - 500px);
}

.nft-section {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  color: #fff;
}
.nft-container {
  background: #333333;
  margin: 1rem;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  column-gap: 5rem;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem 3rem 4rem;
}

.nft-main-img {
  background: linear-gradient(90deg, rgba(228, 128, 14, 1) 33%, rgba(250, 162, 2, 1) 100%);
  padding: 2rem 0;
  border-radius: 2rem;
}

.nft-box > h1 > span {
  color: #faa202;
}

.nft-box > h1 {
  color: #fff;
  font-weight: 500;
  font-size: 41px;
  text-align: center;
  margin-bottom: 1.5rem;
}

.nft-box > h5 {
  text-align: center;
  font-weight: 200;
  font-size: 1.1rem;
  margin-bottom: 1.7rem;
}

.nft-input-container {
  text-align: center;
}

.nft-input-container > h5 {
  font-weight: 200;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.nft-input-group {
  text-align: center;
  display: flex;
  justify-content: center;
}

.nft-input-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #4f4f4f;
  padding: 1rem;
  border-radius: 0.9rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  position: relative;
}

.btn {
  position: absolute;
  top: 0.6rem;
  right: 0.8rem;
  padding: 0.1rem 0.7rem;
  border-radius: 9px;
  border: none;
  background: #faa202;
  color: #fff;
  font-size: 27px;
  cursor: pointer;
}

.btn2 {
  position: absolute;
  top: 0.6rem;
  left: 0.8rem;
  padding: 0.1rem 0.7rem;
  border-radius: 9px;
  border: none;
  background: #faa202;
  color: #fff;
  font-size: 27px;
  cursor: pointer;
}

.nft-input-container > h4 > span {
  color: #faa202;
}

.nft-input-container > h4 {
  font-weight: 200;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.nft-connect-btn {
  text-align: center;
  margin-top: 2rem;
}

.nft-connect-btn > button {
  border-radius: 6px;
  padding: 17px 35px;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0.5rem;
  background: rgb(228, 128, 14);
  background: linear-gradient(90deg, rgba(228, 128, 14, 1) 33%, rgba(250, 162, 2, 1) 100%);
  border: navajowhite;
  color: #fff;
  cursor: pointer;
}
.d-none {
  display: none;
}

.btn-disabled {
  background-color: #6f2c08 !important;
  color: rgb(186, 186, 186);
  cursor: not-allowed !important;
}
.Countdown{
  margin: 10px auto;
  padding-bottom: 20px;
}
.Countdown-col{
  display: inline-block;
}

.Countdown-col-element{
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong{
  font-size: 30px;
}

.CounterBanner{
  flex-direction: row;
}

@media screen and (max-width: 450px) {
  .nft-box > h5 {
    font-size: 0.9rem;
  }
  .CounterBanner{
    flex-direction: column;
  }
  .Countdown-col-element strong{
    font-size: 15px;
  }
  .Countdown-col-element{
    display: inline-block;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
  }
  .nft-box > h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .footerImageWaveContainer {
    top: -10px !important;
  }
  .footerColumn h1 {
    font-size: 1.35rem;
  }
  .footerColumn img {
    max-width: 50px;
    margin: 1rem 0rem;
  }
  .footerColumn a {
    font-size: 0.8rem;
    padding: 0.4rem 0.55rem;
    margin: 0.35rem 0rem;
  }
  .footerColumn {
    margin: 1rem;
  }
  .logoContainer img {
    max-width: 60px;
  }
  .mediumDevicesNavbar__walletInfoContainer {
    flex-direction: column;
  }
}
@media screen and (max-width: 850px) {
  /* #root{
    padding-top: 0 !important;
  } */
  /* Navbar Manager */

  .CounterBanner{
    flex-direction: column;
  }
  .Countdown-col-element strong{
    font-size: 25px;
  }
  .Countdown-col-element{
    display: inline-block;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
  }
  .navBarContainer {
    display: none;
  }
  .navBarContainerMobile {
    /* bottom: 0; */
    position: fixed;
    z-index: 999;
    background: darkgray;
    width: 100vw;
  }
  .bottom_0 {
    bottom: 25px;
  }
  .top_0 {
    top: 0;
  }
  .mediumDevicesNavbar__walletMoneyInformation {
    margin: 10px;
  }
  /* NFT Section */
  .nft-input-container > h4 {
    font-size: 1.5rem;
  }
  .nft-box > h1 {
    font-size: 2rem;
    text-align: center;
  }

  .nft-container {
    flex-wrap: wrap;
  }

  .nft-main-img {
    margin-top: 2rem;
  }
  /* NFT Section-end */
  .connectWalletDarkButton,
  .disconnectWalletDarkButton {
    font-size: 0.9rem;
  }
  .liquidityBottomButton {
    font-size: 0.9rem !important;
  }
  .navbar__body {
    display: none;
  }
  .hamburgerMenuContainer {
    display: flex;
  }
  .navBar {
    justify-content: space-between;
    padding: 0rem 0.75rem;
  }
  .navBarWrapper {
    /* height: 100px; */
  }
  .navBarContainer {
    /* height: 65px; */
  }
  .mobile_nav_popup {
    position: absolute;
    top: 100%;
    z-index: 99999999;
    background: #f2c846;
    right: 10px;
    border: 2px solid;
    border-radius: 8px;
  }
  .mediumDevicesNavbar {
    display: flex;
    flex-direction: row;
    z-index: 15;
    background-color: rgb(245, 209, 99);
    position: absolute;
    bottom: 0;
    align-items: center;
  }
  .farmCellTokenImageMobileNav {
    width: 20%;
    margin-right: 10px;
  }
  .wallet_font_nav_mb {
    font-size: 14px;
  }
  .mediumDevicesNavbar__linksContainer {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: center;
    /* position: absolute;
    top: -100%; */
  }
  .mediumDevicesNavbar__linksContainer a {
    padding: 1rem 0.75rem;
    font-weight: 300 !important;
    font-size: 0.9rem;
    transition: 0.2s;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mediumDevicesNavbar__linksContainer a:hover {
    background-color: #f1c748;
  }
  .mediumDevicesNavbar__walletInfoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    /* border: 2px solid red; */
  }
  .mediumDevicesNavbar__walletMoneyInformation {
    padding: 0.75rem !important;
    margin-right: 1rem;
  }
  .footerImageWaveContainer {
    top: -20px;
  }
  .footerLinksContainer {
    flex-direction: column;
  }
  .footerLinksBottomContainer p {
    font-size: 0.85rem;
  }
  .logoTextContainer span {
    font-size: 1.1rem;
    font-weight: 300;
  }
  .logoContainer {
    margin-left: 0rem !important;
  }
}

@media screen and (max-width: 1030px) {
  .logoContainer {
    /* max-width: 240px; */
    margin-left: 1.5rem;
  }
  .logoContainer img {
    width: 80px;
  }
  .logoContainer span span {
    font-size: 1.3rem !important;
  }
  .linksContainer a {
    font-size: 12px;
    padding: 15px 6px;
  }
  .walletMoneyInformation,
  .mediumDevicesNavbar__walletMoneyInformation {
    font-size: 0.8rem;
    padding: 8px 14px;
  }
  .connectWalletOutlinedButton {
    font-size: 0.8rem;
    padding: 8px 14px;
    margin: 10px 5px 10px 0;
  }
  .nft-container {
    padding: 3rem 1rem 3rem 1rem;
    column-gap: 3rem;
  }
}

@media screen and (min-width: 850px) {
  .navBarContainerMobile {
    display: none;
  }
}
