

#add-liquidity-input-tokena, #add-liquidity-input-tokenb {
    background-color: #f9c348 !important;
}


#add-liquidity-input-tokena svg, #add-liquidity-input-tokenb svg{
    fill: rgb(51, 51, 51) !important;
}

#add-liquidity-input-tokena > div:first-child, #add-liquidity-input-tokenb > div:first-child{
    background-color: #f9c348 !important;
}

.addIconContainer{

    display: flex;
    justify-content: center;
    align-items: center;
}

.addIconContainer div{
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5ae32;
    border-radius: 50%;
}

.addLiquidityIcon{
    height: 20px !important;
    width: 20px !important;
    fill: rgb(51, 51, 51) !important;
}

.addLiquidityInfoCard{
    background-color: #f9c348 !important;
    border: none !important;
}

.addLiquidityInfoHeading{
    color: rgb(51, 51, 51) !important;
}
.liquidityAddIconWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.liquidityAddIconContainer{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #e5ae32;
}
.liquidityAddIcon{
    fill: rgb(51, 51, 51) !important;
}

/* .liquidityBottomButton{
    border: 2px solid red !important;
} */
.chevronDownIcon{
    fill: rgb(51, 51, 51) !important;
}
.importPoolCurrencyName{
    color: rgb(51, 51, 51) !important;
}
